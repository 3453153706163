@import '../../assets/styles/responsive';
@import "../../assets/styles/variables";

.pCompany{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #525B61;
    @include desktop {
      font-size: 20px;
    }
    @include tablet-large {
      font-size: 20px;
    }
}

.modalC {
  &__panel {
    max-width:320px;
  }
  .modalS{
      display: flex;
      align-items: center;
      span{
          margin-left: 20px;
          color: var(--color-neutral-900, #37352F);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
      }
  }
  .Mittle{
      color: var(--color-neutral-900, #37352F);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
  }
  .Mtext{
      color: var(--color-neutral-800, #5B5858);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      .sub{
        color: var(--color-primary, #2FAD6F);
      }
  }
  .Mbtns{
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
          width: 50%;
          cursor: pointer;
          color: var(--color-primary, #2FAD6F);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
          text-decoration-line: underline;
      }
      .Mbtn{
          width: 50%;
          cursor: pointer;
          display: flex;
          justify-content: center;
          border-radius: 4px;
          border: 2px solid var(--color-primary, #2FAD6F);
          background: var(--color-primary, #2FAD6F);
          padding: 12px;
          span{
              color: var(--color-neutral-100, #FAFAFA);
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 160%;
              text-decoration: none;
          }
      }
  }
}
.MainHeader{

}
.only-desktop{
  @include mobile {
    display: none !important;
  }
  @include tablet-large {
    display: none;
  }
  @include tablet {
    display: none;
  }
  @include desktop {
    display: flex;
  }
}
.Header {
    padding: 10px 16px;
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    background: $tenant_blanco_2;
    border-bottom: 1px solid $tenant_color_secundario;
    z-index: 3;
    .imgProfile {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
    &__logo{
      padding-left: 100px;

    }

    &__Desktop {
      padding-right: 100px;
      display: none;
      @include desktop {
        display: flex;
      }
      @include tablet-large {
        display: flex ;
      }
      justify-content: space-between;
      align-items: center;
      span {
        line-height: 16.94px;
        font-style: normal;
        font-family: 'Nunito Sans', 'Helvetica Neue', 'Inter', Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: $tenant_gris_1;
      }
      img {
        margin: 0 10px;
        cursor: pointer;
      }
      img:last-child {
        cursor:pointer;
      }
    }
}
.photoName{
  background: #2ead6e;
  border: 2px solid #2ead6e !important;
  border-radius: 300px;
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  margin: 0 !important;
  margin-right: 10px !important;
  span{
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FAFAFA !important;
  }
}
.HeaderSide {
  @include desktop{
    display: flex;
  }
  @include tablet-large {
    display: flex;
  }
}

.sideDesk {
  display: none;
  @include desktop{
    display: block;
    width: 350px;
  }
  @include tablet-large {
    display: block;
    width: 350px;
  }
}
.sideDeskS {
  display: none;
  @include desktop{
    display: block;
    width: 297px;
  }
  @include tablet-large {
    display: block;
    width: 297px;
  }
}

.sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar {
  cursor: pointer;
  top: 0;
  left: 0;
  width: 308px;
  min-height: calc(100vh - 72px);
  background-color: $tenant_gris_3;
  font-family: 'Nunito Sans', 'Helvetica Neue', 'Inter', Helvetica, Arial, sans-serif;
  padding: 60px;
  z-index: 2;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  scrollbar-color: transparent transparent;
  >li>span{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 3.5px;
  }
  .messageContainer{
    display: flex;
    align-items: center;
    div{
      display: flex;
      align-items: center;
    }
    .countMessages{
      border-radius: 2px;
      background: var(--color-primary, #2FAD6F);
      display: flex;
      justify-content: center;
      width: 33px;
      margin-left: 40px;
      span{
        color: var(--light-theme-background, #FAFAFA);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
        margin: 0;
      }
    }
  }
  &__menu{
    @media only screen and (max-width: $md) {
      margin-top: 10px;
    }
    margin-top: 35px;

    max-width: 220px !important;
    padding-left: 25px;
  }

  &.is-open {
    transform: translateX(-279px);
  }
  &__arrowC {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
  }
  &__logo {
      text-align: start;
      color: $tenant_color_primario;
      font-weight: 700;
      font-size: 20px;
      line-height: 19px;
      margin-bottom: 20px;
  }
  &__titlem{
    font-size: 13px;
    font-weight: 600;
    line-height: 36px;
    color: $tenant_gris_1;
  }
  &__avatar {
      display: flex;
      justify-content: flex-start;
      margin-top: 40px;
      img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
      }
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        color: #69757C;
      }
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        color: #69757C;
        margin: 0;
      }
      div {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-left: 16px;
      }
  }
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #69757C;
  }
  &__option {
    display: flex;
    justify-content: flex-start;
    margin: 15px 0;
    padding-left: 5px;
    a{
      display: flex;
      align-items: center;
      span{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: $tenant_gris_1;
        margin-left: 16px;
        display: inline-block;
      }
    }
  }
  .active{
    a{
      background-color: $tenant_color_secundario;
      span{
        color:  $tenant_blanco;
        padding-right: 20px;
      }
      img {
        .P---imagotipo{
          fill: $tenant_blanco;
        }
        
      }
    }
  }
  .top{
    margin-top: 50px;
    span{
      margin-top: 5px;
      display: inline-block;
      vertical-align: middle;
      color: $tenant_gris_1;
    }
  }
  &__line {
      border-bottom: 0.1px solid #C8CDD0;
  }
  &-menu {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 10px;
      a {
        color: $tenant_gris_1;
        text-decoration: none !important;
        width: auto;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.welcome {
  display: flex;
  margin: 30px 0;
  flex-direction: columns;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #525B61;
    @include desktop {
      font-size: 20px;
    }
    @include tablet-large {
      font-size: 20px;
    }
  }

  img {
    margin-left: 9px;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #75828A;
    @include desktop {
      font-size: 16px;
    }
    @include tablet-large {
      font-size: 16px;
    }
  }
}

.pending {
  padding: 0 16px;
  @include desktop {
    padding: 0 76px;
  }
  @include tablet {
    padding: 0 60px;
  }
  .fail{
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: var(--color-critical-900, #D92D20);
    display: inline-flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    @include tablet {
      max-width: 624px;
    }
    p{
      margin:0;
      color: var(--color-white, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }
    span{
      color: var(--color-white, #FFF);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
  }
  &__containerText {
    margin-bottom: 20px;
    @include desktop {
      //width: 890px;
    }
    @include tablet {
      //width: 90%;
    }
    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      color: #525B61;
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: #525B61;
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: #525B61;
    }
  }
  .panelPending {
    background: #FFFFFF;
    border: 1px solid #E2F7ED;
    box-shadow: 0px 4px 15px rgba(31, 31, 31, 0.05);
    border-radius: 4px;
    @include desktop {
      width: 340px;
    }
    @include tablet {
      width: 340px;
    }
    &__header {
      background-color: #2FAD6F;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 10px 10px 16px;
      border-radius: 4px 4px 0 0px;
      gap: 10px;
      height: 46px;
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        color: #FFFFFF;
      }
    }
    &__body {
      padding: 14px 18px;
      padding-bottom: 0;
      cursor: pointer;
      div {
        padding-bottom: 28px;
        display: flex;
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          color: #525B61;
          margin-left: 10px;
          span {
            font-weight: 500;
          }
        }
      }
    }
  }
}

.complete {
  padding: 0 16px;
  @include desktop {
    padding: 0 76px;
  }
  @include tablet {
    padding: 0 60px;
  }
  .panelComplete {
    width: 341px;
    @include desktop {
      width: 460px;
      height: 205px;
    }
    @include tablet-large {
      width: 460px;
      height: 205px;
    }
    height: 152px;
    border-radius: 16px;
    // background: url('../../assets/images/background_complete.svg') no-repeat center center;
    background-color: #00AA80;
    background-size: cover;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      color: #FFFFFF;
      width: 147px;
    }
    &__btn {
      width: 82px;
      height: 32px;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      @include desktop {
        width: 139px;
        height: 38px;
        font-size: 14px;
      }
      @include tablet-large {
        width: 139px;
        height: 38px;
        font-size: 14px;
      }
    }
  }
}

.panelCamping {
  width: 341px;
  height: 152px;
  border-radius: 16px;
  // background: url('../../assets/images/camp.svg') no-repeat center center;
  background-color: #00AA80;
  background-size: cover;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @include desktop {
    width: 460px;
    height: 205px;
  }
  @include tablet-large {
    width: 460px;
    height: 205px;
  }
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #FFFFFF;
    width: 147px;
    margin: 0;
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
  }
  &__btn {
    width: 82px;
    height: 32px;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
  }
}
.panelMet {
  background: #FFFFFF;
  border: 1px solid #E2F7ED;
  box-shadow: 0px 4px 15px rgba(31, 31, 31, 0.05);
  border-radius: 12px;
  padding: 24px;
  gap: 16px;
  .Mheader{
    margin-top: 10px;
  }
  .Mittle{
    color: var(--color-neutral-900, #37352F);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
}
.Mtext{
    color: var(--color-neutral-800, #5B5858);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    .sub{
      color: var(--color-primary, #2FAD6F);
    }
}
  @include desktop {
    width: 460px;
  }
  @include tablet-large {
    width: 460px;
  }
  &__text {
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: #525B61;
      margin: 16px 0;
      span {
        font-weight: 600;
      }
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      color: #919BA1;
      text-align: justify;
    }
  }
}

.manual {
  padding: 0 16px;
  @include desktop {
    padding: 0 76px;
  }
  @include tablet {
    padding: 0 60px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #525B61;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #525B61;
  }
  .manualCars {
    @include tablet {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80vw;
    }
    @include tablet-large {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80vw;
    }
    .panelManual{
      height: 212px;
      background: #FFFFFF;
      border: 1px solid #E2F7ED;
      box-shadow: 0px 4px 15px rgba(31, 31, 31, 0.05);
      border-radius: 12px;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 16px 0px;
      margin: 24px 0;
      @include tablet {
        width: 270px;
      }
      img {
        width: 42px;
        height: 42px;
      }
      &__under{
        text-decoration-line: underline;
        cursor: pointer;
      }
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        text-align: center;
        color: #525B61;
        padding: 16px 0;
      }
      &__info {
        width: 125px !important;
        height: 32px !important;
      }
    }
  }
}

.modalM {
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__body {
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: #525B61;
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 160%;
      color: #919BA1;
    }
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        color: #919BA1;
    }
  }
}
.modal {
  text-align: center;
  &__panelJ {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
  }
  &__header {
      align-self: flex-end;
  }
  &__warning {
      width: 48px;
      height: 48px;
  }
  &__tittle {
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      color: #525B61;
  }
  &__img {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  &__text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: black;
      text-align: center;
  }
  &__under {
      font-weight: 500;
      font-size: 14px;
      text-decoration-line: underline;
      color: #00AA80;
      margin: 0;
      cursor: pointer;
  }
  &__pt {
      margin-top: 16px !important;
      text-align: center !important;
      color: #00AA80 !important;
  }
}
.auth__main__content__button {
  padding: none !important;
  margin-top: 25px;
}

.sidebar__option:hover{
  opacity: 0.6;
}

.sidebar__option:active{
  opacity: 1;
}

.sidebar__titlem {
  cursor: auto;
}

.header__logo__style {
  height: 52px !important;
}

@media only screen and (max-width: $md) {
.sidebar{display: none;}
}
a span {
  display: inline-block;
  vertical-align: middle; /* Esto alinea verticalmente los elementos */
}
